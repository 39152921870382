<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap28DatesAndTime"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 28: Dates and time" image-name="dates-time.jpg" image-alt="Dates and time"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is UTC?</p></li>
<li><p>How to get the current time?</p></li>
<li><p>How to represent a time in different timezones?</p></li>
<li><p>How to parse a time ?</p></li>
<li><p>How to format a time with a specific layout?</p></li>
<li><p>How to compare two points in time?</p></li>
<li><p>How to add minutes, days, years, seconds to a time?</p></li>
<li><p>What is a wall clock?</p></li>
<li><p>What is a monotonic clock?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Timezone</p></li>
<li><p>Time offset</p></li>
<li><p>UTC</p></li>
<li><p>Wall clock</p></li>
<li><p>Monotonic clock</p></li>
<li><p>Unix Epoch</p></li>
</ul>
<div id="how-to-get-the-current-time" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> How to get the current time? <a href="#how-to-get-the-current-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In many cases, you need to have the current date and time. To get this information, you can call the function <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span></p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/main.go 

package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main() {
    now := time.Now()
    fmt.Printf(&quot;%s&quot;, now)
}</code></pre>
<p>Here we are calling <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span> and then we are printing the result. <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span> returns a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span>. Here is the output of the previous script :</p>
<pre v-highlightjs><code class="go" v-pre >2019-02-12 13:13:55.429243 +0100 CET m=+0.000329111</code></pre>
<figure>
<b-img :src="require('@/assets/images/time_fmt.png')" alt="Time returned by fmt.Print[fig:Time-outputed-by]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Time returned by fmt.Print<span id="fig:Time-outputed-by" label="fig:Time-outputed-by">[fig:Time-outputed-by]</span></figcaption>
</figure>
<p>On the figure <a href="#fig:Time-outputed-by" data-reference-type="ref" data-reference="fig:Time-outputed-by">1</a> you can see the signification of the different portions printed.</p>
<p>You can see that you have the date and the time. In the result, you also have the timezone.</p>
<div class="list">
<p>is a <strong>time offset</strong>. It means that the current time displayed is the Coordinated Universal Time (UTC) + 01 hour and 00 minutes. This time offset is commonly named UTC+01:00</p>
<p>CET means central Central European Time. This is the other name of the <strong>time offset</strong> UTC+01:00</p>
</div>
<p>The timezone is an important element for communicating time between people. If you are developing an API and you output time in a field, you have to tell your users which timezone you use. For instance “13:13:55” in Brazil and “13:13:55” in Stockholm does not refer to the same point in time, not at all!</p>
<pre v-highlightjs><code class="go" v-pre >2009-11-10 23:00:00 +0000 UTC m=+0.000000001</code></pre>
<p>Here is another outputed result of <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span>. You can see here that the timezone is different. The time displayed here is the Coordinated Universal Time (UTC).</p>
<div id="unix-epoch" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Unix Epoch <a href="#unix-epoch"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The “<strong>Unix Epoch</strong>” is the number of seconds elapsed since 1 January 1970 00:00:00 UTC.</p>
<p>With a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> you can get the Unix Epoch with the method <span v-highlightjs><code class="go" v-pre style="display: inline">Unix()</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >now := time.Now()
log.Println(now.Unix())
// &gt; 2021/02/11 18:29:35 1613064575</code></pre>
<p>This way of representing time is unambiguous. There is no time zone involved, and you can store it into a single integer!</p>
<div id="one-time-different-clocks" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> One time, different clocks <a href="#one-time-different-clocks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>From the point of view of the computer, what we call commonly “time” comes in different flavors. But to better understand those flavors, we first need to understand how a computers keep track of time.</p>
<div id="hardware-clock" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Hardware Clock <a href="#hardware-clock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Attached to the motherboard of your computer, you can find a small hardware device that keeps track of time.</p>
<p>Inside this device, you can generally find a piece of quartz that will oscillate at a constant rate. To oscillate, it needs little energy.</p>
<p>When the computer is unplugged, this device will continue to track time (until there is no battery left).</p>
<div id="wall-clock" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Wall Clock <a href="#wall-clock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The wall clock is mainly used for telling the time to the user.</p>
<div id="monotonic-clock" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Monotonic Clock <a href="#monotonic-clock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>There is another clock: the monotonic clock. This clock is used to tell the elapsed time between two points in the time.</p>
<p>The wall clock is subject to change. The clock might be slightly ahead, for example. Consequently, the system might need to synchronize it with a reference clock.</p>
<p>Consequently, we cannot trust this clock to offer a precise measure of the time elapsed.</p>
<p>We need a clock that adds time at a constant rate to measure an elapsed time correctly. This is the monotonic clock.</p>
<p>You can find the idea of constant time variation in its name: monotonous means “Tedious, repetitious, or lacking in variety.”<b-link class="citation" data-cites="wiki-monotonous" href="#wiki-monotonous" >[@wiki-monotonous]</b-link>.</p>
<p>Go use this clock is compute durations.</p>
<div id="format-the-time" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Format the time <a href="#format-the-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, we are going to study a very common use case: formatting the time. You can format and display the time in your program in many ways; some are specified by a norm (for instance, the RFC 3339), some are not and come directly from ingenious developers’ minds.</p>
<div id="how-to-use-the-format-method" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> How to use the <span v-highlightjs><code class="go" v-pre style="display: inline">Format</code></span> method? <a href="#how-to-use-the-format-method"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">Format</code></span> has a single parameter: a string.</p>
<p>This string informs Go how the time needs to be formatted. If you come from another language, you might expect something like that :</p>
<pre v-highlightjs><code class="go" v-pre >YYYY-MM-DD h:i:s</code></pre>
<p>Here YYYY means to display the years with four digits; MM indicates to the program to display the months with two digits... But in Go, the format is written differently :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main() {
    now := time.Now()
    fmt.Println(now.Format(&quot;Mon Jan 2 &quot;))
}</code></pre>
<ul>
<li><p>We first get the current time by calling the function <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span></p></li>
<li><p>Then we format this time with the method <span v-highlightjs><code class="go" v-pre style="display: inline">Format</code></span></p></li>
<li><p>The result is printed</p></li>
</ul>
<p>This code block will output the following string :</p>
<pre v-highlightjs><code class="go" v-pre >Wed Feb 13</code></pre>
<div id="the-reference-time" class="anchor"></div>
<h4 data-number="6.1.0.1"><span class="header-section-number">6.1.0.1</span> The reference time <a href="#the-reference-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The format is <span v-highlightjs><code class="go" v-pre style="display: inline">"Mon Jan 2 "</code></span>. This is because the format in Go is defined based on a <strong>reference date</strong> which is :</p>
<pre v-highlightjs><code class="go" v-pre >Mon Jan 2 15:04:05 -0700 MST 2006</code></pre>
<ul>
<li><p>Monday</p></li>
<li><p>January 2 from the year 2006 at 15:04:05</p></li>
<li><p>MST means <strong>M</strong>ountain <strong>S</strong>tandard <strong>T</strong>ime. This is the timezone (UTC minus 7 hours).</p></li>
</ul>
<div id="specified-formats" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Specified formats <a href="#specified-formats"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> package, you will find some constants with common formats :</p>
<table>
<caption>Some specified Time Formats</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Specification</th>
<th style="text-align: center;">Go Constant</th>
<th style="text-align: center;">Example</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">RFC 3339</td>
<td style="text-align: center;">time.RFC3339</td>
<td style="text-align: center;">2019-02-13T13:48:10+01:00</td>
</tr>
<tr class="even">
<td style="text-align: center;">ANSIC</td>
<td style="text-align: center;">time.ANSIC</td>
<td style="text-align: center;">Wed Feb 13 13:49:17 2019</td>
</tr>
<tr class="odd">
<td style="text-align: center;">RFC 822</td>
<td style="text-align: center;">time.RFC822</td>
<td style="text-align: center;">13 Feb 19 13:49 CET</td>
</tr>
<tr class="even">
<td style="text-align: center;">RFC 822</td>
<td style="text-align: center;">time.RFC822Z</td>
<td style="text-align: center;">13 Feb 19 13:50 +0100</td>
</tr>
<tr class="odd">
<td style="text-align: center;">RFC 1123</td>
<td style="text-align: center;">time.RFC1123</td>
<td style="text-align: center;">Wed, 13 Feb 2019 13:51:06 CET</td>
</tr>
<tr class="even">
<td style="text-align: center;">RFC 339 (with ns)</td>
<td style="text-align: center;">time.RFC3339Nano</td>
<td style="text-align: center;">2019-02-13T13:51:44.298774+01:00</td>
</tr>
<tr class="odd">
<td style="text-align: center;">UNIX</td>
<td style="text-align: center;">time.UnixDate</td>
<td style="text-align: center;">Wed Feb 13 13:52:29 CET 2019</td>
</tr>
</tbody>
</table>
<p>Here is an example of how to use the format <span v-highlightjs><code class="go" v-pre style="display: inline">time.RFC3339</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/formatting/main.go 
package main

import (
    &quot;fmt&quot;
    &quot;time&quot;
)

func main() {
    fmt.Println(time.Now().Format(time.RFC3339))
}</code></pre>
<p>This application will output :</p>
<pre v-highlightjs><code class="go" v-pre >2019-02-15T13:20:36+01:00</code></pre>
<div id="how-to-print-a-date-in-a-specific-location" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> How to print a date in a specific location <a href="#how-to-print-a-date-in-a-specific-location"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you expose dates to your users, you want to print them in their respective timezone :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/location/main.go
package main

//...

func main() {
    now := time.Now()
    fmt.Printf(&quot;%s\n&quot;, now)

    loc, err := time.LoadLocation(&quot;America/New_York&quot;)
    if err != nil {
        panic(err)
    }

    nowNYC := now.In(loc)
    fmt.Printf(&quot;%s\n&quot;, nowNYC)
}</code></pre>
<p>Output :</p>
<pre v-highlightjs><code class="go" v-pre >2021-02-11 18:45:36.949939 +0100 CET m=+0.000601934
2021-02-11 12:45:36.949939 -0500 EST</code></pre>
<p>Those two dates represent the same point in time but in two different locations: France and New York.</p>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">time.LoadLocation</code></span> takes a string as parameter, which represents a <strong>timezone name</strong> from the IANA<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a> Time Zone Database<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>.</p>
<div id="how-to-parse-a-datetime-contained-in-a-string" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> How to parse a date/time contained in a string <a href="#how-to-parse-a-datetime-contained-in-a-string"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can use the <span v-highlightjs><code class="go" v-pre style="display: inline">Parse</code></span> function from the <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> package. This function takes two arguments :</p>
<ul>
<li><p>The format of the time you want to parse (Go needs to know which format your input data has)</p></li>
<li><p>A string that contains your time formatted</p></li>
</ul>
<p>This function will return a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> or an error (if the parsing of the time has failed). Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/parse/main.go 

timeToParse := &quot;2019-02-15T07:33-05:00&quot;
t,err := time.Parse(&quot;2006-01-02T15:04-07:00&quot;,timeToParse)
if err != nil {
    panic(err)
}

fmt.Println(t)</code></pre>
<ul>
<li><p>We put the string <span v-highlightjs><code class="go" v-pre style="display: inline">"2019-02-15T07:33-05:00"</code></span> into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">timeToParse</code></span>.</p></li>
<li><p>Then we call <span v-highlightjs><code class="go" v-pre style="display: inline">time.Parse</code></span></p></li>
</ul>
<p>How did I manage to find the right format? You have to look at an example and then build your format string to make each part of the date match with the reference date.</p>
<p>The format <span v-highlightjs><code class="go" v-pre style="display: inline">time.RFC3339</code></span> is used by default when a date is unmarshaled from JSON.</p>
<div id="how-to-get-the-time-elapsed-between-two-points" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> How to get the time elapsed between two points <a href="#how-to-get-the-time-elapsed-between-two-points"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To get the time elapsed between two points, you can use the method <span v-highlightjs><code class="go" v-pre style="display: inline">Sub</code></span> of <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/elapsed/main.go 

start := time.Now()
err := ioutil.WriteFile(&quot;/tmp/thisIsATest&quot;, []byte(&quot;TEST&quot;), 0777)
if err != nil {
    panic(err)
}
end := time.Now()
elapsed := end.Sub(start)
fmt.Printf(&quot;process took %s&quot;, elapsed)</code></pre>
<ul>
<li><p>We save the current time in the variable <span v-highlightjs><code class="go" v-pre style="display: inline">start</code></span></p></li>
<li><p>We write data to a file.</p></li>
<li><p>Then we get the current time again. We save that time into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">end</code></span></p></li>
<li><p>The elapsed duration is computed with the call <span v-highlightjs><code class="go" v-pre style="display: inline">end.Sub(start)</code></span></p></li>
<li><p>We print the duration returned (<span v-highlightjs><code class="go" v-pre style="display: inline">elapsed</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> )</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/process_took_muSeconds.png')" alt="Elapsed Time Duration"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Elapsed Time Duration</figcaption>
</figure>
<div id="time.duration-type" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> time.Duration type <a href="#time.duration-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> denotes elapsed time between two moments. Here is its definition in the standard library :</p>
<pre v-highlightjs><code class="go" v-pre >// A Duration represents the elapsed time between two instants
// as an int64 nanosecond count. The representation limits the
// largest representable duration to approximately 290 years.
type Duration int64</code></pre>
<p>Its underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span>. It represents the number of nanoseconds between two points in time.</p>
<div id="how-to-check-if-a-time-is-between-two-points" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> How to check if a time is between two points <a href="#how-to-check-if-a-time-is-between-two-points"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> has two handy methods :</p>
<pre v-highlightjs><code class="go" v-pre >Before

After</code></pre>
<p>Those two methods accept a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> as input.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/comparison/main.go 

location, err  := time.LoadLocation(&quot;UTC&quot;)
if err != nil {
    panic(err)
}

firstJanuary1980 := time.Date(1980,1,1,0,0,0,0,location)

timeToParse := &quot;2019-02-15T07:33-02:00&quot;
t,err := time.Parse(&quot;2006-01-02T15:04-07:00&quot;,timeToParse)
if err != nil {
    panic(err)
}

now := time.Now()
if t.After(firstJanuary1980) &amp;&amp; t.Before(now) {
    fmt.Println(t, &quot;is between &quot;, firstJanuary1980, &quot;and&quot;,now)
}else {
    fmt.Println(&quot;not in between&quot;)
}</code></pre>
<div id="how-to-add-days-hours-minutes-seconds-to-a-time" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> How to add days, hours, minutes, seconds to a time <a href="#how-to-add-days-hours-minutes-seconds-to-a-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>When you need to add a specific number of days, months, or year, you can use the method <span v-highlightjs><code class="go" v-pre style="display: inline">AddDate</code></span></p>
<ul>
<li>This method takes three integers as parameters:</li>
</ul>
<ol type="1">
<li><p>a number of year(s)</p></li>
<li><p>a number of month(s)</p></li>
<li><p>a number of days(s)</p></li>
</ol></li>
<li><p>When you need to add a more specific amount of time, you can use <span v-highlightjs><code class="go" v-pre style="display: inline">Add</code></span> (ex: nanoseconds, microseconds, milliseconds, seconds, minutes, hours).</p>
<ul>
<li>This method takes a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> as input which represents <strong>a number of nanoseconds</strong>.</li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/add/main.go 

now := time.Now()

// + 12 years
// + 1 Month
// + 3 days
now = now.AddDate(12,1,3)


now = now.Add(time.Nanosecond * 1)
now = now.Add(time.Microsecond * 5)
now = now.Add(time.Millisecond * 5)
now = now.Add(time.Second * 5)
now = now.Add(time.Minute * 5)
now = now.Add(time.Hour * 5)</code></pre>
<p>The package time defines constants that represent a nanosecond, microsecond, millisecond, second, minute, and hour <strong>in nanoseconds.</strong> We can use those constants to create custom durations.</p>
<div id="iterate-over-time" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Iterate over time <a href="#iterate-over-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You want to display to your users each day between a start date and an end date.</p>
<p>To achieve this, you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">AddDate</code></span> method.</p>
<p>Those three quantities will be added to the date. The modified date is then returned :</p>
<pre v-highlightjs><code class="go" v-pre >start, err := time.Parse(&quot;2006-01-02&quot;, &quot;2019-02-19&quot;)
startPlus1Day := start.AddDate(0,0,1)</code></pre>
<p>We added one day, zero month, and zero year to the start date. We can use this function inside a for loop to iterate over each day between two limit dates :</p>
<pre v-highlightjs><code class="go" v-pre >// dates-and-time/iterate/main.go 

func main() {
    start, err := time.Parse(&quot;2006-01-02&quot;, &quot;2019-02-19&quot;)
    if err != nil {
        panic(err)
    }
    end , err := time.Parse(&quot;2006-01-02&quot;, &quot;2020-07-17&quot;)
    if err != nil {
        panic(err)
    }
    for i := start; i.Unix() &lt; end.Unix(); i = i.AddDate(0, 0, 1) {
        fmt.Println(i.Format(time.RFC3339))
    }
}</code></pre>
<p>Here we define two dates : <span v-highlightjs><code class="go" v-pre style="display: inline">start</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">end</code></span>. Those dates will represent our start and stop points in time.</p>
<p>Then we can create a for loop. A for loop has three parts :</p>
<ol type="1">
<li><p><strong>The init</strong>: this instruction is executed just once before the for loop as started</p></li>
<li><p><strong>The condition</strong>: this expression must return a boolean (true or false). It is evaluated before every iteration. If it’s false, the loop will stop.</p></li>
<li><p><strong>The post statement</strong>: this statement is executed after each iteration</p></li>
</ol>
<p>The init phase of our for loop is simple we create a new variable named<span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>. Then we check before each iteration that the end date (variable <span v-highlightjs><code class="go" v-pre style="display: inline">end</code></span>) is bigger than the current iteration date (variable <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>). The post statement will add one day to the current iteration date (with the <span v-highlightjs><code class="go" v-pre style="display: inline">AddDate</code></span> method).</p>
<p>With this loop, we will iterate over each day between the <span v-highlightjs><code class="go" v-pre style="display: inline">start</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">end</code></span> date.</p>
<p>What if you want to iterate over hours, minutes or seconds? You will have to use the <span v-highlightjs><code class="go" v-pre style="display: inline">Add</code></span> method :</p>
<pre v-highlightjs><code class="go" v-pre >for i := start; i.Unix() &lt; end.Unix(); i = i.Add(time.Minute*2) {
    fmt.Println(i.Format(time.RFC3339))
}</code></pre>
<p>Instead of adding one day to the date, we add 2 minutes. Hence the for loop will take us from the 2nd February 2019 at midnight to the 17th July 2019 with 2 minutes steps. That’s exactly 370.080 iterations!</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the signification of UTC?</p></li>
<li><p>How to get the current time?</p></li>
<li><p>What is the underlying type of <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> ?</p></li>
<li><p>True or False.<span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 19:23:03.465196 +0100 CET</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 13:23:03.465196 -0500 EST</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 18:25:06.465196 +0000 UTC</code></span> represent three different points in time.</p></li>
<li><p>How to add 5 minutes to a time <span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span>?</p></li>
<li><p>How to add two years and one day to a time <span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span>?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the signification of UTC?</p>
<ol type="1">
<li>Coordinated Universal Time</li>
</ol></li>
<li><p>How to get the current time?</p>
<ol type="1">
<li>time.Now()</li>
</ol></li>
<li><p>What is the underlying type of <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> ?</p>
<ol type="1">
<li>int64</li>
</ol></li>
<li><p>True or False.<span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 19:23:03.465196 +0100 CET</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 13:23:03.465196 -0500 EST</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 18:25:06.465196 +0000 UTC</code></span> represent 3 different points in time.</p>
<ol type="1">
<li><p>False, they represent the <strong>same</strong> point in time.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 18:25:06.623301 +0000 UTC</code></span> is the representation of this instant in UTC</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 19:23:03.465196 +0100 CET</code></span> is the representation of this instant with the timezone of Paris (France)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">2021-02-11 13:23:03.465196 -0500 EST</code></span> is the representation of this instant with the timezone of New York (US)</p></li>
</ol></li>
<li><p>How to add 5 minutes to a time <span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span>?</p>
<pre v-highlightjs><code class="go" v-pre >t = t.Add(time.Minute*5)</code></pre></li>
<li><p>How to add two years and one day to a time <span v-highlightjs><code class="go" v-pre style="display: inline">t</code></span>?</p>
<pre v-highlightjs><code class="go" v-pre >t = t.AddDate(2,0,1)</code></pre></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The package <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> contains all you need to manipulate time.</p></li>
<li><p>To get the current time, you can use <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span> which will return a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span>.</p></li>
<li><p>To convert a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> into a string you can use the method <span v-highlightjs><code class="go" v-pre style="display: inline">Format</code></span>. It takes a string as parameter: the layout.</p></li>
<li><p>To parse a string representing a time, you can use the function <span v-highlightjs><code class="go" v-pre style="display: inline">time.Parse(layout, value string)(time.Time, error)</code></span></p></li>
<li><p>The <strong>layout</strong> is a string that represents the “format” of the time.</p></li>
<li><p>The layout is exprimed with a reference date : “Mon Jan 2 15:04:05 MST 2006”</p></li>
<li><p>Common formats are defined as constants in the <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> package. The most commonly used is <span v-highlightjs><code class="go" v-pre style="display: inline">time.RFC3339</code></span></p></li>
<li><p>The methods <span v-highlightjs><code class="go" v-pre style="display: inline">Before</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">After</code></span> can be used to compare two times</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span> represents the elapsed duration between two points in time.</p></li>
<li><p>You can represent a time in a different time zone with the method <span v-highlightjs><code class="go" v-pre style="display: inline">In</code></span> that takes a <span v-highlightjs><code class="go" v-pre style="display: inline">*time.Location</code></span> as input (which can be loaded with <span v-highlightjs><code class="go" v-pre style="display: inline">time.LoadLocation(tzName)</code></span>)</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>IANA: Internet Assigned Numbers Authority<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>List can be found here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap28DatesAndTime"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap27EnumIotaAndBitmask'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Enum, Iota & Bitmask</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap29DataStorageFilesAndDatabases'}">
									<p><u><small>Next</small></u></p>
									<p><small>Data storage : files and databases</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Dates and time - Practical Go Lessons"
const description = "How to format a time with a specific layout with Go ? How to represent a time in different timezones with Go? How to use the time package with Go"

export default {
  name: "Chap28DatesAndTime",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
